import Toast from 'utils/Toast'
import FunctionGolbal from './FunctionGlobal'

export default function Utils() {
  return (
    <>
      <Toast />
      <FunctionGolbal />
    </>
  )
}
