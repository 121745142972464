export default function Managerment() {
  return (
    <>
      <a href="/management" className="hover:text-red-100 flex h-full items-center gap-2 text-white">
        <i className="fa-solid fa-bars text-sm"></i>
        <p>Managerment</p>
      </a>
    </>
  )
}
