import Privacy from 'components/Footer/Privacy'
export default function Foot() {
  return (
    <>
      <div className="bg-gray py-10">
        <div className="flex justify-center ">
          <Privacy />
        </div>
      </div>
    </>
  )
}
